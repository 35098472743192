import React from 'react'
import { useEffect, useRef } from 'react'
import Navbar from '../components/navbar'

const TypeOne = ({ handleOSK, changeType ,ppii, keyboard
}) => {


    
  const crntDigit = useRef('1')
  const displa = useRef()
  const nth = useRef(0)
  const lastPress = useRef()
  const isCorrect = useRef()
  const info = useRef()
  const place = useRef()
  const show = useRef()

  const keyPress = (event) => {
    document.removeEventListener("keydown", keyPress, false);
    let a = event.key
    lastPress.current.innerHTML = a
    if (a == crntDigit.current) {
      isCorrect.current.innerHTML = 'correct'
      isCorrect.current.style.color = '#12c812'
      nth.current += 1
      displa.current.innerHTML = displa.current.innerHTML + crntDigit.current
      place.current.innerHTML = nth.current + 1
      show.current.innerHTML = " "
      crntDigit.current = ppii[nth.current]
    }
    else {
      isCorrect.current.innerHTML = 'incorrect'
      isCorrect.current.style.color = 'red'
    }
    document.addEventListener("keydown", keyPress, false);
  };

  const handleClick = () => {
    show.current.innerHTML = crntDigit.current
  }

  const handleKeyboard = (e) => {
    keyPress(({ 'key': e.target.id }))
  }
  const reset = () => {
    displa.current.innerHTML = '3.'
    place.current.innerHTML = 1
    nth.current = 0
    crntDigit.current = ppii[nth.current]
    show.current.innerHTML = " "
    isCorrect.current.innerHTML = 'none'
    isCorrect.current.style.color = '#000000'
    lastPress.current.innerHTML = 'none'
  }




    useEffect(() => {
        document.addEventListener("keydown", keyPress, false);
        return () => {
          document.removeEventListener("keydown", keyPress, false);
        };
      }, []);
    






    return (
        <>
            <Navbar handleOSK={handleOSK} reset={reset} changeType={changeType} />
            <div className='main'>
                <div className="content">
                    <div className="info" ref={info}>
                        <span className='s1'>Last pressed key :</span>
                        <span className='s2' ref={lastPress}>none</span>
                        <span className='s3' ref={isCorrect}>none</span>
                        <span className='s2' ref={place}>1</span>
                    </div>
                    <div className='value' ref={displa}>
                        3.
                    </div>
                    <div className="show" ref={info}>
                        <span ref={show}></span>
                        <button onClick={handleClick}>Show current digit</button>
                    </div>
                </div>
                <div className="keyboard" ref={keyboard}>
                    <div id='1' className='key' onClick={handleKeyboard}>1</div>
                    <div id='2' className='key' onClick={handleKeyboard}>2</div>
                    <div id='3' className='key' onClick={handleKeyboard}>3</div>
                    <div id='4' className='key' onClick={handleKeyboard}>4</div>
                    <div id='5' className='key' onClick={handleKeyboard}>5</div>
                    <div id='6' className='key' onClick={handleKeyboard}>6</div>
                    <div id='7' className='key' onClick={handleKeyboard}>7</div>
                    <div id='8' className='key' onClick={handleKeyboard}>8</div>
                    <div id='9' className='key' onClick={handleKeyboard}>9</div>
                    <div id='0' className='key' onClick={handleKeyboard}>0</div>

                </div>

            </div>

        </>
    )
}

export default TypeOne